import styles from "./styles.module.css"
import { useTranslation } from "react-i18next"

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.footer}>
      <div className={styles.footerCopyright}>
        <div className="text-center fs-6">
          Copyright © {t("logoTitleFooter")} {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}

import {
  Applicant,
  Inventor,
  Owner,
  Patent,
  Priority,
  Publication,
} from "../../../shared/model"
import { PriorityInfo } from "../../priority/ui"
import { ApplicantInfo } from "../../applicant/ui"
import { OwnerInfo } from "../../owner/ui"
import { InventorInfo } from "../../inventor/ui"
import { useTranslation } from "react-i18next"
import { PublicationInfo } from "../../publication/ui"

export const NatPatent = ({ patent }: { patent: Patent }) => {
  const { t } = useTranslation()
  return (
    <div className="">
      <div className="text-primary-emphasis fw-bold fs-3 text-center mb-4">
        {t("natPatentHeader")} № {patent.patentNumber}
      </div>
      <section className="mb-5">
        <h5 className="text-info-emphasis bg-body-secondary p-2">
          {t("bibliographySection")}
        </h5>
        <div className="container">
          <div className="row py-2">
            <div className={`col-lg-6 col-md-6 col-sm-12 text-secondary`}>
              (11) {t("field11")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent.patentNumber}
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (21) {t("field21")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent.applicationNumber}
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              {t("expiredDate")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent.expiredDate}
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              {t("field44")} <br/>
              {t("field45")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent?.publications?.map((publication: Publication, index) => {
                return <PublicationInfo key={index} publication={publication} />
              })}
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (51) {t("field51")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">{patent.ipcs}</div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (54) {t("field54")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <span>{patent.titleNat}</span>
              <br />
              <span className="d-inline-block mt-1 text-muted">{patent.titleRu}</span>
            </div>
          </div>
          <div className="priority">
            <div className="row py-2 border-top">
              <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
                (31, 32, 33) {t("field31")} / {t("field32")} / {t("field33")}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                {patent?.priorities?.map((priority: Priority, index) => {
                  return <PriorityInfo key={index} priority={priority} />
                })}
              </div>
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (86) {t("field86")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent.applicationPCTNumber}
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (87) {t("field87datenum")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent.applicationPCTPubNumber} &nbsp;{" "}
              {patent.applicationPCTPubDate}
            </div>
          </div>
          <div className="applicants">
            <div className="row py-2 border-top">
              <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
                (71) {t("field71")}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                {patent?.applicants?.map((applicant: Applicant, index) => {
                  return <ApplicantInfo key={index} applicant={applicant} />
                })}
              </div>
            </div>
          </div>
          <div className="row py-2 border-top">
            <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
              (72) {t("field72")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {patent?.inventors?.map((inventor: Inventor, index) => {
                return <InventorInfo key={index} inventor={inventor} />
              })}
            </div>
          </div>
          <div className="owners">
            <div className="row py-2 border-top">
              <div className="col-lg-6 col-md-6 col-sm-12 text-secondary">
                (73) {t("field73")}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                {patent?.owners?.map((owner: Owner, index) => {
                  return <OwnerInfo key={index} owner={owner} />
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

import { GoBackLink } from "../../shared/ui/GoBackLink"
import { useAppDispatch, useAppSelector } from "../../shared/hooks/hooks"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { fetchOneAsync } from "../../entities/natPatent/model"
import { NatPatent } from "../../entities/natPatent/ui"

const PatentPage = () => {
  const dispatch = useAppDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchOneAsync(id))
  }, [dispatch, id])
  const patent = useAppSelector((state) => {
    return state.patents.patent
  })
  return (
    <>
      <NatPatent patent={patent} />
      <GoBackLink />
    </>
  )
}

export default PatentPage

import styles from "./styles.module.css"
import logo from "./img/AZ_Logo_gerb.png"
import LanguageSelector from "../translation/LanguageSelector"
import React from "react"
import { useTranslation } from "react-i18next"

export const Header = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.header}>
      <div className="container d-flex flex-column justify-content-around h-100">
        <div className="row my-3">
          <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12 fs-3">
            <div className={styles.headerLogo}>{t("logoTitleHeader")}</div>
          </div>
          <div className="col-lg-4 col-xl-4 d-lg-block d-sm-none d-none text-center">
          </div>
          <div className="col-lg-4 col-xl-4 d-lg-block d-sm-none d-none text-center">
            <img src={logo} alt="" width="100" />
          </div>
          <LanguageSelector />
        </div>
      </div>
    </div>
  )
}

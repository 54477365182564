import { Link } from "react-router-dom"
import { PatentShort } from "../../../shared/model"
import { useTranslation } from "react-i18next"

export function NatPatentShort({ patent }: { patent: PatentShort }) {
  const { t } = useTranslation()
  return (
    <div className="container border-bottom mb-5 pb-3">
      <div className="row mb-2">
        <div className="col-md-4 col-lg-4 col-sm-12 g-0">
          <div className="text-primary-emphasis px-3 fs-5">
            {t("patNumberTitle")}
          </div>
          <Link className="px-3" to={`/patent/${patent.patentNumber}`}>
            {patent.patentNumber}
          </Link>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-12 g-0">
          <div className="text-primary-emphasis px-3 fs-5">
            {t("appliNumberTitle")}
          </div>
          <div className="text-muted px-3">{patent.applicationNumber}</div>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-12 g-0">
          <div className="text-primary-emphasis px-3 fs-5">
            {t("title")}
          </div>
          <div className="text-muted px-3">{patent.titleNat}</div>
        </div>
      </div>
    </div>
  )
}

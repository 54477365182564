import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiInstance } from "../../../shared/axios"
import { converter } from "../../../shared/util/converter"

interface PatentShortState {
  patentShort: any
}

const initialState: PatentShortState = {
  patentShort: [],
}

export const fetchPatentShort = createAsyncThunk(
  "patentShort/fetchAll",
  async (data: any) => {
    return await getPatentsShortList(data)
  },
)

export const patentShortModel = createSlice({
  name: "patentShort",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatentShort.fulfilled, (state, action) => {
      state.patentShort = action.payload
    })
  },
})

export async function getPatentsShortList(data: any) {
  let res
  await apiInstance
    .post("/patents/search", converter(data))
    .then((response) => {
      res = response.data.content ?? []
    })
    .catch((error) => {
      console.log(error)
      res = []
    })
  return res
}

export const reducer = patentShortModel.reducer

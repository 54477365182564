import React, { useState } from "react"
import i18n from "./i18n"
import styles from "./styles.module.css"

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language) // i18n.language contains the language assigned to lng in i18n.js file.

  const chooseLanguage = (e: any) => {
    e.preventDefault()
    i18n.changeLanguage(e.target.value) // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(e.target.value)
  }

  return (
    <div className="container d-flex justify-content-start mt-3">
      <div className="position-relative text-center">
        <label className={styles.label} htmlFor="az">
          AZ
        </label>
        <input
          className={styles.lng}
          type="radio"
          value="az"
          name="country"
          id="az"
          onChange={chooseLanguage}
        />
      </div>
      <div className="mx-1 position-relative text-center">
        <label className={styles.label} htmlFor="ru">
          RU
        </label>
        <input
          className={styles.lng}
          type="radio"
          value="ru"
          name="country"
          id="ru"
          onChange={chooseLanguage}
        />
      </div>
    </div>
  )
}

export default LanguageSelector

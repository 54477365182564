import { Route, Routes, Navigate } from "react-router-dom"
import PatentsListPage from "./patentsList"
import PatentPage from "./patent"
import MainPage from "./main"

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/patents" element={<PatentsListPage />} />
      <Route path="patent">
        <Route path=":id" element={<PatentPage />} />
      </Route>
    </Routes>
  )
}

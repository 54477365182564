import { Link } from "react-router-dom"
import styles from "./styles.module.css"
import { useTranslation } from "react-i18next"

export const GoBackLink = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.goBackLink}>
      <Link to={"/"}>{t("backToSearch")}</Link>
    </div>
  )
}

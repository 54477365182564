import { Owner } from "../../../shared/model"

export const OwnerInfo = ({ owner }: { owner: Owner }) => {
  return (
    <div className="mb-2 d-flex flex-column">
        <div>{owner.nameNat} &nbsp; {owner.countryCode}</div>
        <div className="text-muted">{owner.nameRu}</div>
    </div>
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiInstance } from "../../../shared/axios"

interface PatentsState {
  patent: any
}

const initialState: PatentsState = {
  patent: {},
}

export const fetchOneAsync = createAsyncThunk(
  "patents/fetchOne",
  async (id: any) => {
    return await fetchPatentByNum(id)
  },
)

export const patentModel = createSlice({
  name: "patents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOneAsync.fulfilled, (state, action) => {
      state.patent = action.payload
    })
  },
})

export async function fetchPatentByNum(id: any) {
  let data
  await apiInstance
    .get(`/patents/${id}`)
    .then((response) => {
      data = response.data
    })
    .catch((error) => console.log(error))
  return data
}

export const reducer = patentModel.reducer

import { Publication } from "../../../shared/model"

export const PublicationInfo = ({
  publication,
}: {
  publication: Publication
}) => {
  return (
    <>
      {publication.year + "-" +publication.month}  &nbsp; {publication.publicationDate}  &nbsp; {publication.publicationCode}
      <br />
    </>
  )
}

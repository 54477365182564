import { useAppSelector } from "../../shared/hooks/hooks"
import { NatPatentShort } from "../../entities/natPatentShort/ui"
import { GoBackLink } from "../../shared/ui/GoBackLink"
import { useTranslation } from "react-i18next"

const PatentsListPage = () => {
  const data: any[] = useAppSelector((state) => {
    return state.patentShort.patentShort
  })
  const { t } = useTranslation()

  if (data.length > 0) {
    return (
      <>
        {data?.map((i) => {
          return (
            <NatPatentShort
              key={i?.applicationNumber + i?.patentNumber}
              patent={i}
            />
          )
        })}
        <GoBackLink />
      </>
    )
  } else {
    return (
      <>
        <div className="text-danger-emphasis fs-5">
            {t("notFound")}
        </div>
        <GoBackLink />
      </>
    )
  }
}

export default PatentsListPage

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationsInRU from "../locales/ru/translation.json"
import translationsInAZ from "../locales/az/translation.json"

const resources = {
  ru: {
    translation: translationsInRU,
  },
  az: {
    translation: translationsInAZ,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources, // resources are important to load translations for the languages.
    lng: "az", // It acts as default language. When the site loads, content is shown in this language.
    debug: false,
    fallbackLng: "ru", // use ru if selected language is not available
    interpolation: {
      escapeValue: false,
    },
    ns: "translation", // namespaces help to divide huge translations into multiple small files.
    defaultNS: "translation",
  })

export default i18n

import React from "react"
import { Header } from "../shared/ui"
import { Footer, HeaderTitle, MainContainer } from "../shared/ui"

function App() {
  return (
    <>
      <Header />
      <HeaderTitle />
      <MainContainer />
      <Footer />
    </>
  )
}

export default App

import { configureStore } from "@reduxjs/toolkit"
import { patentShortModel } from "../../features/search/model"
import { patentModel } from "../../entities/natPatent/model"

export const store = configureStore({
  reducer: {
    patentShort: patentShortModel.reducer,
    patents: patentModel.reducer,
  },
})

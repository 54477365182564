import { Applicant } from "../../../shared/model"

export const ApplicantInfo = ({ applicant }: { applicant: Applicant }) => {
  return (
    <div className="mb-2 d-flex flex-column">
        <div>{applicant.nameNat} &nbsp; {applicant.countryCode}</div>
        <div className="text-muted">{applicant.nameRu}</div>
    </div>
  )
}

import { SubmitHandler, useForm } from "react-hook-form"
import {FormModelDTO, Priority} from "../../../../shared/model"
import { useAppDispatch } from "../../../../shared/hooks/hooks"
import { fetchPatentShort } from "../../model"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const SearchForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormModelDTO>()

  const onSubmit: SubmitHandler<FormModelDTO> = async (data, event) => {
    event?.preventDefault()
    try {
      dispatch(fetchPatentShort(data))
      navigate("/patents")
    } catch (e: any) {
      console.log(e.message)
    }
  }

  const { t } = useTranslation()


  const patternTrim: any = {
    value: /^[^\s]+(?:$|.*[^\s]+$)/,
    message: "Entered value cant start/end or contain only white spacing",
  }

  const patternDate: any = {
    value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    message: "Date must be a valid date in the format YYYY-MM-DD",
  }


  return (
    <div className="container text-primary-emphasis">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 fs-5">
          <label className="form-label">(11) {t("field11")}</label>
          <input
            className="form-control border-3"
            id="patentNumber"
            {...register("patentNumber", { pattern: patternTrim })}
            name="patentNumber"
          />
          {errors.patentNumber && (
            <p className="text-danger fs-6" role="alert">
              {errors.patentNumber.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(21) {t("field21")}</label>
          <input
            className="form-control border-3"
            {...register("applicationNumber", { pattern: patternTrim })}
          />
          {errors.applicationNumber && (
            <p className="text-danger fs-6" role="alert">
              {errors.applicationNumber.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(51) {t("field51")}</label>
          <input
            className="form-control border-3"
            {...register("ipcs", { pattern: patternTrim })}
          />
          {errors.ipcs && (
            <p className="text-danger fs-6" role="alert">
              {errors.ipcs.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(54) {t("field54")}</label>
          <input
            className="form-control border-3"
            {...register("titleNat", { pattern: patternTrim })}
          />
          {errors.titleNat && (
            <p className="text-danger fs-6" role="alert">
              {errors.titleNat.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(71) {t("field71")}</label>
          <input
            className="form-control border-3"
            {...register("applicants", { pattern: patternTrim })}
          />
          {errors.applicants && (
            <p className="text-danger fs-6" role="alert">
              {errors.applicants.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(72) {t("field72")}</label>
          <input
            className="form-control border-3"
            {...register("inventors", { pattern: patternTrim })}
          />
          {errors.inventors && (
            <p className="text-danger fs-6" role="alert">
              {errors.inventors.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(73) {t("field73")}</label>
          <input
            className="form-control border-3"
            {...register("owners", { pattern: patternTrim })}
          />
          {errors.owners && (
            <p className="text-danger fs-6" role="alert">
              {errors.owners.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(31) {t("field31")}</label>
          <input
            className="form-control border-3"
            {...register("prioritiesNumber", { pattern: patternTrim })}
          />
          {errors.prioritiesNumber && (
            <p className="text-danger fs-6" role="alert">
              {errors.prioritiesNumber.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(33) {t("field33")}</label>
          <input
            className="form-control border-3"
            {...register("prioritiesCountry", { pattern: patternTrim })}
          />
          {errors.prioritiesCountry && (
            <p className="text-danger fs-6" role="alert">
              {errors.prioritiesCountry.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(32) {t("field32")}</label>
          <input
            className="form-control border-3"
            type="text"
            placeholder={"YYYY-MM-DD"}
            {...register("prioritiesDate", { pattern: patternDate })}
          />
          {errors.prioritiesDate && (
            <p className="text-danger fs-6" role="alert">
              {errors.prioritiesDate.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(86) {t("field86")}</label>
          <input
            className="form-control border-3"
            {...register("applicationPCTNumber", { pattern: patternTrim })}
          />
          {errors.applicationPCTNumber && (
            <p className="text-danger fs-6" role="alert">
              {errors.applicationPCTNumber.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(87) {t("field87")}</label>
          <input
            className="form-control border-3"
            type="text"
            {...register("applicationPCTPubNumber", { pattern: patternTrim })}
          />
          {errors.applicationPCTPubNumber && (
            <p className="text-danger fs-6" role="alert">
              {errors.applicationPCTPubNumber.message}
            </p>
          )}
        </div>

        <div className="mb-3 fs-5">
          <label className="form-label">(87) {t("field87date")}</label>
          <input
              className="form-control border-3"
              type="text"
              placeholder={"YYYY-MM-DD"}
              {...register("applicationPCTPubDate", { pattern: patternDate })}
          />
          {errors.applicationPCTPubDate && (
              <p className="text-danger fs-6" role="alert">
                {errors.applicationPCTPubDate.message}
              </p>
          )}
        </div>

        <div className="mt-5">
          <input
            className="btn btn-secondary fs-5"
            type="submit"
            value={t("search")}
          />
          <input
            className="btn btn-outline-dark mx-3 fs-5"
            type="reset"
            value={t("reset")}
            onClick={() => reset()}
          />
        </div>
      </form>
    </div>
  )
}

import styles from "./styles.module.css"
import { useTranslation } from "react-i18next"

export const HeaderTitle = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.headerTitleContainer}>
      <div className="row text-center fs-1">
        <div className={styles.headerTitle}>{t("registryTitle")}</div>
      </div>
    </div>
  )
}

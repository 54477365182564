import { Inventor } from "../../../shared/model"

export const InventorInfo = ({ inventor }: { inventor: Inventor }) => {
  return (
    <div className="mb-2 d-flex flex-column">
        <div>{inventor.nameNat} &nbsp; {inventor.countryCode}</div>
        <div className="text-muted">{inventor.nameRu}</div>
    </div>
  )
}

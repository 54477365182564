import styles from "./styles.module.css"
import { Routing } from "../../pages"

export const MainContainer = () => {
  return (
    <div className={styles.mainContainer}>
      <div className="container m-auto my-4">
        <Routing />
      </div>
    </div>
  )
}

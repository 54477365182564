export interface Criterias {
  criterias: Criteria[]
  pageSize?: number
  page?: number
}

export interface Criteria {
  field?: string
  value?: string
  operator?: string
}

export enum Operators {
  equal = "EQUAL",
  equaldate = "EQUALDATE",
  like = "LIKE",
  after = "AFTER",
  before = "BEFORE",
}

export const converter = (data: Object) => {
  let criterias: Criterias = {
    criterias: [],
    page: 0,
    pageSize: 50,
  }

  for (const [key, value] of Object.entries(data)) {
    let criteria: Criteria = {}
    criteria.field = key
    if (value !== "") {
      criteria.value = "%" + value + "%"
    }
    criteria.operator = Operators.like
    if (key.toString() === "prioritiesNumber") {
      criteria.field = "priorities.applicationNumber"
    }
    if (key.toString() === "prioritiesCountry") {
      criteria.field = "priorities.countryCode"
    }
    if (key.toString() === "prioritiesDate") {
      criteria.field = "priorities.date"
      criteria.value = value
      criteria.operator = Operators.equaldate
    }
    if (key.toString() === "applicants") {
      criteria.field = "publications.applicants.nameNat"
    }
    if (key.toString() === "owners") {
      criteria.field = "publications.owners.nameNat"
    }
    if (key.toString() === "inventors") {
      criteria.field = "publications.inventors.nameNat"
    }
    if (key.toString() === "applicationPCTPubDate") {
      criteria.value = value
      criteria.operator = Operators.equaldate
    }
    criterias.criterias.push(criteria)
  }

  return criterias
}
